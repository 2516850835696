import React, { useCallback, useEffect } from 'react';
import { Animated, Platform, ScrollView, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import AlertService from '../libs/AlertService';
import Api from '../libs/Api';
import DataService, {
  withObservables,
  withMapProps,
} from '../libs/DataService';
import { formatDuration } from '../libs/DIYUtils';
import {
  pay,
  subscribe,
  unsubscribe,
  enqueue,
  dequeue,
  checkIn,
  watch,
} from '../libs/LessonActions';
import { getRatingLabel } from '../libs/LessonUtils';
import NavigationService from '../libs/NavigationService';
import {
  getLessonStatus,
  lessonStatus,
  subscriptionTime,
  isSubscribed,
  checkInEnd,
} from '../libs/Rules';
import ShareService from '../libs/ShareService';
import Theme from '../libs/Theme';
import useSync from '../hooks/useSync';
import BookedIcon from '../icons/BookedIcon';
import CalendarIcon from '../icons/CalendarIcon';
import CheckInIcon from '../icons/CheckInIcon';
import ClipboardIcon from '../icons/ClipboardIcon';
import InfoIcon from '../icons/InfoIcon';
import LiveIcon from '../icons/LiveIcon';
import NotificationIcon from '../icons/NotificationIcon';
import NotificationOnIcon from '../icons/NotificationOnIcon';
import PinIcon from '../icons/PinIcon';
import StarIcon from '../icons/StarIcon';
import withNavParams from '../components/withNavParams';
import I18n from '../components/I18n';
import LoadingView from '../components/LoadingView';
import LessonMiniCard from '../components/LessonMiniCard';
import LessonDetails from '../components/LessonDetails';
import LessonDetailsSubscribed from '../components/LessonDetailsSubscribed';
import LessonActionBar from '../components/LessonActionBar';
import Header from '../components/Header';

class LessonScreen extends React.Component {
  constructor(props) {
    super(props);

    this.scrollRef = React.createRef();
    this.scrollValue = new Animated.Value(0);
    this.onScrollEvent = Animated.event(
      [{ nativeEvent: { contentOffset: { y: this.scrollValue } } }],
      { useNativeDriver: true, listener: this.onScroll },
    );

    const scrollY =
      Theme.dimensions.windowHeight -
      Theme.dimensions.topSpace -
      Theme.dimensions.headerHeight +
      10;

    const headerY = this.scrollValue.interpolate({
      inputRange: [scrollY - 0.1, scrollY],
      outputRange: [-2 * Theme.dimensions.windowHeight, 0],
      extrapolate: 'clamp',
    });
    this.headerStyle = { transform: [{ translateY: headerY }] };

    const backY = this.scrollValue.interpolate({
      inputRange: [scrollY - 0.1, scrollY],
      outputRange: [200, 0],
      extrapolate: 'clamp',
    });
    this.backStyle = { transform: [{ translateY: backY }] };

    this.state = {
      miniCardPosition: 0,
      detailsPosition: 0,
    };
  }

  onLayout = e => {
    const actionBarHeight = 80;

    const miniCardHeight = e.nativeEvent.layout.height;

    const availableVisibleArea =
      Theme.dimensions.windowHeight -
      Theme.dimensions.topSpace -
      Theme.dimensions.headerHeight -
      actionBarHeight -
      Theme.dimensions.bottomSpace;

    const miniCardPosition =
      Math.min(miniCardHeight, availableVisibleArea) +
      actionBarHeight +
      Theme.dimensions.bottomSpace -
      1;

    const detailsPosition = miniCardPosition + availableVisibleArea + 1;

    this.setState({ miniCardPosition, detailsPosition });

    this.scrollRef.current
      ?.getNode()
      .scrollTo({ y: miniCardPosition, animated: false });
  };

  onScroll = e => {
    const { y } = e.nativeEvent.contentOffset;
    if (y < 1) {
      this.goBack();
    }
  };

  onScrollEndDrag = e => {
    const { targetContentOffset, contentOffset, velocity } = e.nativeEvent;

    // no android não temos o `targetContentOffset`, então temos usar outra abordagem.
    const isSwipeDown =
      Platform.OS === 'ios'
        ? targetContentOffset.y < 1
        : contentOffset.y < this.state.miniCardPosition && velocity.y > 0;

    if (isSwipeDown) {
      this.goBack();
    }
  };

  onPressBackground = () => {
    this.goBack();
  };

  onPressDetails = () => {
    this.scrollRef.current
      ?.getNode()
      .scrollTo({ y: this.state.detailsPosition });
  };

  onPressBackDetails = () => {
    this.scrollRef.current
      ?.getNode()
      .scrollTo({ y: this.state.miniCardPosition });
  };

  goBack = () => {
    // evita sobrepor mais de um evento de goBack pois há várias maneiras
    // de sair da tela: onScroll, onScrollEndDrag, onPressBackground
    if (this.isExiting) {
      return;
    }
    this.isExiting = true;
    NavigationService.goBack();
  };

  share = () => {
    const { id, lesson, trainer } = this.props;

    const date = moment(lesson.start_datetime);

    const message =
      lesson.available_vacancies > 0 && lesson.available_vacancies < 15
        ? 'shareLessonWithVacancies'
        : 'shareLesson';

    const messageParams = {
      lesson: lesson.name,
      weekDay: date.format('dddd'),
      day: date.format('DD/MM'),
      time: date.format('H:mm'),
      trainer: trainer.name,
      count: lesson.available_vacancies,
      url: `https://app.mude.fit/aula/${id}`,
    };

    ShareService.share(message, messageParams, 'lesson', id);
  };

  getStructure = (
    lesson,
    status,
    user,
    sponsor,
    enrollment,
    product,
    place,
  ) => {
    switch (status) {
      case lessonStatus.CAN_PAY: {
        const plan = product?.plans
          .filter(p => p.active)
          .sort((a, b) => a.recurrence_months - b.recurrence_months)[0];

        if (!lesson || !product || !plan) {
          return {
            theme: 'magenta',
            actionIcon: ClipboardIcon,
          };
        }

        return {
          theme: 'magenta',
          actionIcon: ClipboardIcon,
          actionText: 'schedule',
          price: plan.price,
          onPressAction: () => pay(product, plan, lesson),
        };
      }

      case lessonStatus.CAN_SUBSCRIBE:
        return {
          theme: 'magenta',
          actionIcon: ClipboardIcon,
          actionText: 'schedule',
          onPressAction: () => subscribe(this.props.id),
          // price: 0, // imprime a palavra "Grátis" no botão, é melhor usar após amadurecer as aulas pagas
        };

      case lessonStatus.CAN_LIVE_SUBSCRIBE:
        return {
          theme: 'magenta',
          actionIcon: NotificationIcon,
          actionText: 'createReminder',
          onPressAction: () => subscribe(this.props.id),
        };

      case lessonStatus.SUBSCRIBED:
        return {
          actionIcon: BookedIcon,
          actionText: 'youAreScheduled',
          onPressAction: () => unsubscribe(this.props.id),
          onPressCancel: () => unsubscribe(this.props.id),
        };

      case lessonStatus.LIVE_SUBSCRIBED:
        return {
          actionIcon: NotificationOnIcon,
          actionText: 'reminderCreated',
          onPressAction: () => unsubscribe(this.props.id),
          onPressCancel: () => unsubscribe(this.props.id),
        };

      case lessonStatus.CHECKIN:
        return {
          theme: 'magenta',
          actionIcon: PinIcon,
          actionText: 'checkInNow',
          onPressAction: () => checkIn(this.props.id),
          onPressCancel: () => unsubscribe(this.props.id),
        };

      case lessonStatus.CHECKIN_DONE:
        return {
          actionIcon: CheckInIcon,
          actionText: 'checkinDone',
        };

      case lessonStatus.WATCH:
        return {
          theme: 'magenta',
          actionIcon: LiveIcon,
          actionText: checkInEnd(lesson) ? 'watch' : 'watchLive',
          onPressAction: () => watch(this.props.id),
        };

      case lessonStatus.TO_REVIEW:
        return {
          theme: 'magenta',
          actionIcon: StarIcon,
          actionText: 'didYouLikeTheLesson',
          onPressAction: () =>
            NavigationService.navigate('Review', { enrollment: enrollment.id }),
        };

      case lessonStatus.REVIEW_DONE:
        return {
          actionText: <I18n>{getRatingLabel(enrollment.rating)}</I18n>,
          rating: enrollment.rating,
          onPressAction: () =>
            NavigationService.navigate('Review', { enrollment: enrollment.id }),
        };

      case lessonStatus.CANCELED:
        return {
          actionText: 'lessonCancelled',
          onPressAction: () => {
            AlertService.show({
              title: 'lessonCancelled',
              message: 'weHadToCancel',
              messageI18nParams: {
                reason: lesson.cancellation_reason
                  ? `${lesson.cancellation_reason}. `
                  : '',
              },
            });
          },
        };

      case lessonStatus.LESSON_MISSED:
        return {
          actionText: 'weHaveMissedYou',
        };

      case lessonStatus.EXCLUSIVE: {
        return {
          actionIcon: InfoIcon,
          actionIconProps: { color: sponsor?.main_color },
          actionText: (
            <I18n params={{ sponsor: sponsor?.name }}>
              classOnlyForSponsorCta
            </I18n>
          ),
          onPressAction: () => {
            AlertService.show({
              title: 'classOnlyForSponsorTitle',
              message: 'classOnlyForSponsorContent',
              messageI18nParams: { sponsor: sponsor?.name },
              dismissText: 'classOnlyForSponsorDismiss',
              onDismiss: () =>
                NavigationService.navigate('Sponsor', { id: lesson.sponsor }),
            });
          },
        };
      }

      case lessonStatus.USER_LIMIT:
        return {
          actionText: 'scheduleLimitReachedTitle',
          onPressAction: () => {
            AlertService.show({
              title: 'scheduleLimitReachedTitle',
              message: 'scheduleLimitReachedContent',
              messageI18nParams: { count: user.subscriptions_limit },
              confirmText: 'scheduleLimitReachedConfirm',
              dismissText: 'scheduleLimitReachedDismiss',
              onDismiss: () => NavigationService.navigate('Profile'),
            });
          },
        };

      case lessonStatus.NOT_OPEN_YET: {
        const _subscriptionTime = subscriptionTime(lesson, user, place);
        return {
          actionIcon: CalendarIcon,
          actionText: (
            <I18n
              i18nKey="timeBeforeScheduling"
              params={{
                timeToGo: moment(lesson.start_datetime)
                  .subtract(_subscriptionTime, 'minutes')
                  .fromNow(true),
              }}
            />
          ),
          onPressAction: () => {
            AlertService.show({
              title: 'notOpenYetTitle',
              message: 'notOpenYetContent',
              messageI18nParams: {
                subscriptionTime: formatDuration(_subscriptionTime * 60),
              },
            });
          },
        };
      }

      case lessonStatus.ALREADY_HAPPENED: {
        return {
          actionText: 'happenedClass',
        };
      }

      case lessonStatus.LESSON_FULL:
        return {
          theme: 'magenta',
          actionIcon: NotificationIcon,
          actionText: 'letMeKnowAboutNewVacancies',
          onPressAction: () => enqueue(this.props.id),
        };

      case lessonStatus.WARN_ME:
        return {
          actionIcon: NotificationOnIcon,
          actionText: 'lessonNotificationsOn',
          onPressAction: () => dequeue(this.props.id),
        };
    }

    return {};
  };

  render() {
    const {
      loading,
      error,
      lesson,
      lessonGroup,
      enrollment,
      user,
      sponsor,
      sponsorGroups,
      place,
      neighborhood,
      trainer,
      modality,
      product,
    } = this.props;

    const { miniCardPosition, detailsPosition } = this.state;

    const status = getLessonStatus(
      lesson,
      enrollment,
      user,
      sponsorGroups,
      place,
    );

    const structure =
      this.getStructure(
        lesson,
        status,
        user,
        sponsor,
        enrollment,
        product,
        place,
      ) || {};

    if (!lesson) {
      return <LoadingView loading={loading} empty={error || 'notFound'} />;
    }

    const isNative = Platform.select({ isNative: true });

    return (
      <>
        <ScrollView
          ref={this.scrollRef}
          bounces={false}
          showsVerticalScrollIndicator={false}
          style={styles.scrollView}
        >
          <View style={styles.container}>
            <View>
              <LessonMiniCard
                status={status}
                lesson={lesson}
                lessonGroup={lessonGroup}
                trainer={trainer}
                place={place}
                product={product}
                sponsor={sponsor}
              />
            </View>
            {isSubscribed(enrollment) && isNative ? (
              <LessonDetailsSubscribed
                lesson={lesson}
                place={place}
                trainer={trainer}
                modality={modality}
                sponsor={sponsor}
                enrollment={enrollment}
                onPressCancel={structure.onPressCancel}
              />
            ) : (
              <LessonDetails
                lesson={lesson}
                lessonGroup={lessonGroup}
                place={place}
                neighborhood={neighborhood}
                trainer={trainer}
                modality={modality}
                sponsor={sponsor}
              />
            )}
          </View>
        </ScrollView>
        <Animated.View style={[styles.header, this.headerStyle]}>
          <Header title="lessonDetails" onPressShare={this.share} />
        </Animated.View>
        <LessonActionBar
          theme={structure.theme}
          actionText={structure.actionText}
          actionIcon={structure.actionIcon}
          actionIconProps={structure.actionIconProps}
          price={structure.price}
          rating={structure.rating}
          backStyle={this.backStyle}
          onPressAction={structure.onPressAction}
          onPressDetails={this.onPressDetails}
          onPressBack={this.onPressBackDetails}
          onPressShare={this.share}
        />
      </>
    );
  }
}

function LessonScreen_Sync(props) {
  const { navigation, id, lesson, lessonGroup } = props;

  const sync = useCallback(async () => {
    await DataService.syncLesson(id);
    if (lesson?.lesson_group) {
      await DataService.syncLessonGroup(lesson.lesson_group);
    }
    if (lessonGroup?.product) {
      await DataService.syncProduct(lessonGroup.product);
    }
    try {
      await DataService.syncEnrollments();
      await Api.getMe();
    } catch (_error) {}
  }, [id, lesson, lessonGroup]);

  const { syncing, error } = useSync(sync);

  useEffect(() => {
    const _unsubscribe = navigation.addListener('focus', sync);
    return () => _unsubscribe();
  }, [sync, navigation]);

  useEffect(() => {
    const intervalId = setInterval(sync, 30 * 1000);
    return () => clearInterval(intervalId);
  }, [sync]);

  return <LessonScreen {...props} loading={syncing} error={error} />;
}

const styles = StyleSheet.create({
  loading: {
    backgroundColor: Theme.color.white,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    height: 350,
  },
  scrollView: {
    height: Theme.dimensions.windowHeight - 80 - Theme.dimensions.bottomSpace,
  },
  container: {
    backgroundColor: Theme.color.white,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
});

const mapStateToProps = ({ user, emergencyContacts }) => {
  return {
    user,
    emergencyContacts,
  };
};

const enhance5 = withObservables(
  ['product', 'neighborhood'],
  ({ product, neighborhood }) => ({
    product: DataService.observeProduct(product),
    neighborhood: DataService.observeNeighborhood(neighborhood),
  }),
);

const enhance4 = withMapProps(({ lessonGroup, place }) => ({
  product: lessonGroup?.product,
  neighborhood: place?.neighborhood,
}));

const enhance3 = withObservables(
  ['place', 'trainer', 'modality', 'lessonGroup', 'sponsor'],
  ({ place, trainer, modality, lessonGroup, sponsor }) => ({
    place: DataService.observePlace(place),
    trainer: DataService.observeTrainer(trainer),
    modality: DataService.observeModality(modality),
    lessonGroup: DataService.observeLessonGroup(lessonGroup),
    sponsor: DataService.observeSponsor(sponsor),
    sponsorGroups: DataService.observeSponsorGroupsBySponsor(sponsor),
  }),
);

const enhance2 = withMapProps(({ lesson }) => ({
  place: lesson?.place,
  trainer: lesson?.trainer,
  modality: lesson?.modality,
  lessonGroup: lesson?.lesson_group,
  sponsor: lesson?.sponsor,
}));

const enhance = withObservables(['id'], ({ id }) => ({
  lesson: DataService.observeLesson(id),
  enrollment: DataService.observeEnrollmentByLessonId(id),
}));

export default withNavParams(
  connect(mapStateToProps)(
    enhance(enhance2(enhance3(enhance4(enhance5(LessonScreen_Sync))))),
  ),
);
