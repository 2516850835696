export const __DEV__ = process.env.NODE_ENV === 'development';

const isProduction = __DEV__ ? false : true;

export const AUTH_STORAGE_KEY = isProduction
  ? 'AuthService'
  : 'AuthService__DEV';

export const STORAGE_KEY = isProduction
  ? 'StorageService'
  : 'StorageService__DEV';

export const REDUX_PERSIST_KEY = isProduction
  ? 'ReduxStore'
  : 'ReduxStore__DEV';

export const WATERMELON_DB_NAME = isProduction
  ? 'watermelon'
  : 'watermelon_dev';

export const API_URL = isProduction
  ? 'https://d1qpk27urgboi8.cloudfront.net/api'
  : 'https://api-test.mude.fit/api';

export const API_ALTERNATIVE_URL = isProduction
  ? 'https://api.mude.fit/api'
  : 'https://api-test.mude.fit/api';

export const LIVES_CITY = isProduction ? 25 : 15;

export const ARENA_UNIMED_EVENT = isProduction ? '92' : '92';
export const ACADEMIA_UNIMED_EVENT = isProduction ? '50' : '50';
export const MUDE_1_HABITO_RIO_EVENT = isProduction ? '3' : '3';
