import React from 'react';
import { Linking, InteractionManager } from 'react-native';

import { NavigationContainer } from '@react-navigation/native';
import {
  createStackNavigator,
  TransitionPresets,
  CardStyleInterpolators,
} from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import AnalyticsService from '../libs/AnalyticsService';
import AuthService from '../libs/AuthService';
import NavigationService from '../libs/NavigationService';
import NotificationService from '../libs/NotificationService';
import SentryService from '../libs/SentryService';
import Theme from '../libs/Theme';
import LiveIcon from '../icons/LiveIcon';
import GroupClassIcon from '../icons/GroupClassIcon';
import DiyIcon from '../icons/DiyIcon';
import SearchIcon from '../icons/SearchIcon';
import TabBarLabel from './TabBarLabel';

// Auth screens
import FrontDoorScreen from '../screens/FrontDoorScreen';
import CreateAccountScreen from '../screens/CreateAccountScreen';
import LoginScreen from '../screens/LoginScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import RecoverPasswordScreen from '../screens/RecoverPasswordScreen';
import CreateAccountModalScreen from '../screens/CreateAccountModalScreen';
import LoginModalScreen from '../screens/LoginModalScreen';
import ForgotPasswordModalScreen from '../screens/ForgotPasswordModalScreen';

// Search screens
// import SearchScreen from '../screens/SearchScreen';
// import SearchFilterScreen from '../screens/SearchFilterScreen';
// import LocalityFilterScreen from '../screens/LocalityFilterScreen';
// import ModalityFilterScreen from '../screens/ModalityFilterScreen';
// import DateTimeFilterScreen from '../screens/DateTimeFilterScreen';
// import DurationFilterScreen from '../screens/DurationFilterScreen';
// import TrainerFilterScreen from '../screens/TrainerFilterScreen';

// GroupClass screens
import LivesScreen from '../screens/LivesScreen';
// import GroupClassScreen from '../screens/GroupClassScreen';
// import MapScreen from '../screens/MapScreen';
import EventScreen from '../screens/EventScreen';
// import EventAboutScreen from '../screens/EventAboutScreen';
import LessonScreen from '../screens/LessonScreen';
// import LiveLessonScreen from '../screens/LiveLessonScreen';
import LiveChatScreen from '../screens/LiveChatScreen';
import LivePlayerScreen from '../screens/LivePlayerScreen';
// import SponsorAdScreen from '../screens/SponsorAdScreen';
import LessonSpotScreen from '../screens/LessonSpotScreen';
// import CheckoutScreen from '../screens/CheckoutScreen';
// import AddCreditCardScreen from '../screens/AddCreditCardScreen';
// import LessonDonationScreen from '../screens/LessonDonationScreen';
// import ThanksScreen from '../screens/ThanksScreen';
// import PlansScreen from '../screens/PlansScreen';
// import CheckInScreen from '../screens/CheckInScreen';
import ReviewScreen from '../screens/ReviewScreen';

// DIY screens
// import DIYScreen from '../screens/DIYScreen';
import CollectionScreen from '../screens/CollectionScreen';
// import CollectionAboutScreen from '../screens/CollectionAboutScreen';
import WorkoutScreen from '../screens/WorkoutScreen';
// import WorkoutExperienceScreen from '../screens/WorkoutExperienceScreen';
// import RecordedWorkoutScreen from '../screens/RecordedWorkoutScreen';
// import LibraryScreen from '../screens/LibraryScreen';
// import ExerciseScreen from '../screens/ExerciseScreen';
// import ExerciseInstructionsScreen from '../screens/ExerciseInstructionsScreen';
// import WorkoutDonationScreen from '../screens/WorkoutDonationScreen';

// Trainer screens
// import TrainersScreen from '../screens/TrainersScreen';
import TrainerScreen from '../screens/TrainerScreen';
// import TrainerDonationScreen from '../screens/TrainerDonationScreen';

// Profile screens
import ProfileScreen from '../screens/ProfileScreen';
// import SettingsScreen from '../screens/SettingsScreen';
// import PersonalDataScreen from '../screens/PersonalDataScreen';
// import ChangePasswordScreen from '../screens/ChangePasswordScreen';
// import LanguagesScreen from '../screens/LanguagesScreen';
// import NotificationScreen from '../screens/NotificationScreen';
// import AnamnesisScreen from '../screens/AnamnesisScreen';
// import EmergencyContactScreen from '../screens/EmergencyContactScreen';
// import WorkoutOptionsScreen from '../screens/WorkoutOptionsScreen';
// import PaymentsScreen from '../screens/PaymentsScreen';
// import FeatureFlagScreen from '../screens/FeatureFlagScreen';
// import RemoveAccountScreen from '../screens/RemoveAccountScreen';
// import HelpScreen from '../screens/HelpScreen';
// import SponsorScreen from '../screens/SponsorScreen';
import CompleteDataModalScreen from '../screens/CompleteDataModalScreen';

import QrWorkoutScreen from '../screens/QrWorkoutScreen';

import UnimedScreen from '../screens/UnimedScreen';

import SlugScreen from '../screens/SlugScreen';
import ProfileIcon from '../icons/ProfileIcon';
import GroupClassScreen from '../screens/GroupClassScreen';

const linking = {
  prefixes: [
    'https://app.mude.fit',
    'http://app.mude.fit',
    'mudefit://app.mude.fit',
    'mudefit://',
  ],
  config: {
    screens: {
      Collection: 'colecao/:id',
      CompleteDataModal: {
        path: 'completar-cadastro',
        parse: { flow: flow => flow.split('|') },
        stringify: { flow: flow => flow.join('|') },
      },
      CreateAccount: 'criar-conta',
      CreateAccountModal: 'criar-conta-modal',
      DIY: 'on-demand',
      Event: 'evento/:id',
      ForgotPassword: 'esqueci-minha-senha',
      ForgotPasswordModal: 'esqueci-minha-senha-modal',
      FrontDoor: '',
      GroupClass: 'aulas',
      Lesson: 'aula/:id',
      LessonSpot: 'vaga/:id',
      Lives: 'lives',
      LiveChat: 'live/chat/:id',
      LivePlayer: 'live/play',
      Login: 'login',
      LoginModal: 'login-modal',
      Profile: 'perfil',
      Unimed: 'unimed',
      Review: 'avaliacao',
      RecoverPassword: 'recuperar-minha-senha',
      Search: 'busca',
      QrWorkout: 'treino-qr/:id',
      Slug: ':slug',
      Tab: '',
      Trainer: 'professor/:id',
      Workout: 'treino/:id',
    },
  },
};

function forFade(props, finalOpacity = 0.5) {
  return {
    opacity: props.current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [0, finalOpacity],
      extrapolate: 'clamp',
    }),
  };
}

const defaultScreenOptions = {
  // por enquanto vamos remover a transição de telas pois pode ficar pesado
  // quando entendermos bem o nosso público e a performance, podemos valiar
  // e ativar ou não novamente
  // animationEnabled: true,
  cardOverlayEnabled: true,
  cardStyle: { backgroundColor: Theme.color.white },
  headerShown: false,
  ...TransitionPresets.SlideFromRightIOS,
  cardStyleInterpolator: props => ({
    cardStyle: CardStyleInterpolators.forHorizontalIOS(props).cardStyle,
    overlayStyle: forFade(props), // garante uma transição suave na opacidade do overlay mesmo se for entre telas horizontal e vertical
  }),
};

const verticalScreenOptions = {
  cardStyle: {
    backgroundColor: Theme.color.transparent,
  },
  cardStyleInterpolator: props => ({
    cardStyle: CardStyleInterpolators.forVerticalIOS(props).cardStyle,
    overlayStyle: forFade(props),
  }),
  gestureDirection: 'vertical',
};

const workoutExperienceScreenOptions = {
  headerShown: false,
  gestureEnabled: false,
  cardStyleInterpolator: props => ({
    cardStyle: forFade(props, 1),
  }),
};

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const tabBarOptions = {
  activeTintColor: Theme.color.magenta,
  adaptive: false,
  inactiveTintColor: Theme.color.lightGray,
  style: {
    height: Theme.dimensions.tabBarHeight + Theme.dimensions.bottomSpace,
  },
  tabStyle: {
    paddingVertical: 11,
  },
};

const GroupClassTabOptions = {
  tabBarLabel: props => <TabBarLabel title="lessons" {...props} />,
  tabBarIcon: ({ color }) => <GroupClassIcon color={color} />,
};

const ProfileTabOptions = {
  tabBarLabel: props => <TabBarLabel title="profile" {...props} />,
  tabBarIcon: ({ color }) => <ProfileIcon color={color} />,
};

function TabScreen() {
  return (
    <Tab.Navigator tabBarOptions={tabBarOptions} initialRouteName="Lives">
      <Tab.Screen
        name="GroupClass"
        component={GroupClassScreen}
        options={GroupClassTabOptions}
      />
      <Tab.Screen
        name="Profile"
        component={ProfileScreen}
        options={ProfileTabOptions}
      />
    </Tab.Navigator>
  );
}

export default class Routes extends React.PureComponent {
  componentDidMount() {
    InteractionManager.runAfterInteractions(() => {
      this.currentRoute = NavigationService.getActiveRoute();
      this.trackRoute(this.currentRoute);

      Linking.addEventListener('url', this.onLinkingURL);

      this.openNotificationUrl();

      setTimeout(() => {
        if (
          AuthService.isLogged &&
          NavigationService.getActiveRoute().name === 'FrontDoor'
        ) {
          NavigationService.navigate('Tab');
        }
      });
    });
  }

  componentWillUnmount() {
    Linking.removeEventListener('url', this.onLinkingURL);
  }

  onLinkingURL = ({ url }) => {
    NavigationService.openUrl(url);
  };

  openNotificationUrl = async () => {
    try {
      const url = await NotificationService.getInitialURL();
      if (url) {
        NavigationService.openUrl(url);
      }
    } catch (error) {
      SentryService.log(error);
    }
  };

  onStateChange = state => {
    const previousRoute = this.currentRoute;
    const currentRoute = NavigationService.getActiveRoute(state);
    if (previousRoute?.name !== currentRoute?.name) {
      this.trackRoute(currentRoute);
    }
    this.currentRoute = currentRoute;
  };

  trackRoute = route => {
    if (route?.name) {
      AnalyticsService.setCurrentScreen(route.name, route.params?.id);
    }
  };

  render() {
    return (
      <NavigationContainer
        ref={NavigationService.ref}
        linking={linking}
        onStateChange={this.onStateChange}
      >
        <Stack.Navigator
          headerMode="screen"
          screenOptions={defaultScreenOptions}
          initialRouteName={AuthService.isLogged ? 'Tab' : 'FrontDoor'}
        >
          <Stack.Screen name="FrontDoor" component={FrontDoorScreen} />
          <Stack.Screen name="CreateAccount" component={CreateAccountScreen} />
          <Stack.Screen name="Login" component={LoginScreen} />
          <Stack.Screen
            name="ForgotPassword"
            component={ForgotPasswordScreen}
          />
          <Stack.Screen
            name="RecoverPassword"
            component={RecoverPasswordScreen}
          />
          <Stack.Screen
            name="CreateAccountModal"
            component={CreateAccountModalScreen}
            options={verticalScreenOptions}
          />
          <Stack.Screen
            name="LoginModal"
            component={LoginModalScreen}
            options={verticalScreenOptions}
          />
          <Stack.Screen
            name="ForgotPasswordModal"
            component={ForgotPasswordModalScreen}
            options={verticalScreenOptions}
          />

          <Stack.Screen name="Tab" component={TabScreen} />

          {/**
           * ==================
           * Search screens
           * ==================
           */}
          {/* <Stack.Screen
            name="SearchFilter"
            component={SearchFilterScreen}
            options={verticalScreenOptions}
          />
          <Stack.Screen
            name="LocalityFilter"
            component={LocalityFilterScreen}
            options={verticalScreenOptions}
          />
          <Stack.Screen
            name="ModalityFilter"
            component={ModalityFilterScreen}
            options={verticalScreenOptions}
          />
          <Stack.Screen
            name="DateTimeFilter"
            component={DateTimeFilterScreen}
            options={verticalScreenOptions}
          />
          <Stack.Screen
            name="DurationFilter"
            component={DurationFilterScreen}
            options={verticalScreenOptions}
          />
          <Stack.Screen
            name="TrainerFilter"
            component={TrainerFilterScreen}
            options={verticalScreenOptions}
          /> */}

          {/**
           * ==================
           * GroupClass screens
           * ==================
           */}
          {/* <Stack.Screen name="Map" component={MapScreen} /> */}
          <Stack.Screen name="Event" component={EventScreen} />
          {/* <Stack.Screen
            name="EventAbout"
            component={EventAboutScreen}
            options={verticalScreenOptions}
          /> */}
          <Stack.Screen name="Lesson" component={LessonScreen} />
          {/* <Stack.Screen
            name="LiveLesson"
            component={LiveLessonScreen}
            options={verticalScreenOptions}
          /> */}
          <Stack.Screen name="LiveChat" component={LiveChatScreen} />
          <Stack.Screen name="LivePlayer" component={LivePlayerScreen} />
          {/* <Stack.Screen
            name="SponsorAd"
            component={SponsorAdScreen}
            options={verticalScreenOptions}
          /> */}
          <Stack.Screen
            name="LessonSpot"
            component={LessonSpotScreen}
            options={verticalScreenOptions}
          />
          {/* <Stack.Screen
            name="Checkout"
            component={CheckoutScreen}
            options={verticalScreenOptions}
          />
          <Stack.Screen
            name="AddCreditCard"
            component={AddCreditCardScreen}
            options={verticalScreenOptions}
          />
          <Stack.Screen
            name="LessonDonation"
            component={LessonDonationScreen}
            options={verticalScreenOptions}
          />
          <Stack.Screen
            name="Thanks"
            component={ThanksScreen}
            options={verticalScreenOptions}
          />
          <Stack.Screen
            name="Plans"
            component={PlansScreen}
            options={verticalScreenOptions}
          />
          <Stack.Screen
            name="CheckIn"
            component={CheckInScreen}
            options={verticalScreenOptions}
          />*/}
          <Stack.Screen
            name="Review"
            component={ReviewScreen}
            options={verticalScreenOptions}
          />

          {/**
           * ===========
           * DIY screens
           * ===========
           */}
          <Stack.Screen name="Collection" component={CollectionScreen} />
          {/*<Stack.Screen
            name="CollectionAbout"
            component={CollectionAboutScreen}
            options={verticalScreenOptions}
          />
          */}
          <Stack.Screen name="Workout" component={WorkoutScreen} />
          {/*<Stack.Screen
            name="WorkoutExperience"
            component={WorkoutExperienceScreen}
            options={workoutExperienceScreenOptions}
          />
          <Stack.Screen
            name="RecordedWorkout"
            component={RecordedWorkoutScreen}
            options={workoutExperienceScreenOptions}
          />
          <Stack.Screen name="Library" component={LibraryScreen} />
          <Stack.Screen
            name="Exercise"
            component={ExerciseScreen}
            options={verticalScreenOptions}
          />
          <Stack.Screen
            name="ExerciseInstructions"
            component={ExerciseInstructionsScreen}
            options={verticalScreenOptions}
          />
          <Stack.Screen
            name="WorkoutDonation"
            component={WorkoutDonationScreen}
            options={verticalScreenOptions}
          /> */}

          {/**
           * ===============
           * Trainer screens
           * ===============
           */}
          {/* <Stack.Screen name="Trainers" component={TrainersScreen} /> */}
          <Stack.Screen name="Trainer" component={TrainerScreen} />
          {/* <Stack.Screen
            name="TrainerDonation"
            component={TrainerDonationScreen}
            options={verticalScreenOptions}
          /> */}

          {/**
           * ===============
           * Profile screens
           * ===============
           */}
          <Stack.Screen name="Profile" component={ProfileScreen} />
          {/*<Stack.Screen name="Settings" component={SettingsScreen} />
          <Stack.Screen name="PersonalData" component={PersonalDataScreen} />
          <Stack.Screen
            name="ChangePassword"
            component={ChangePasswordScreen}
          />
          <Stack.Screen name="Languages" component={LanguagesScreen} />
          <Stack.Screen name="Notification" component={NotificationScreen} />
          <Stack.Screen name="Anamnesis" component={AnamnesisScreen} />
          <Stack.Screen
            name="EmergencyContact"
            component={EmergencyContactScreen}
          />
          <Stack.Screen
            name="WorkoutOptions"
            component={WorkoutOptionsScreen}
          />
          <Stack.Screen name="Payments" component={PaymentsScreen} />
          <Stack.Screen name="FeatureFlag" component={FeatureFlagScreen} />
          <Stack.Screen name="RemoveAccount" component={RemoveAccountScreen} />
          <Stack.Screen name="Help" component={HelpScreen} />
          <Stack.Screen name="Sponsor" component={SponsorScreen} />*/}
          <Stack.Screen
            name="CompleteDataModal"
            component={CompleteDataModalScreen}
            options={verticalScreenOptions}
          />

          <Stack.Screen name="QrWorkout" component={QrWorkoutScreen} />

          <Stack.Screen name="Unimed" component={UnimedScreen} />

          <Stack.Screen name="Slug" component={SlugScreen} />
        </Stack.Navigator>
      </NavigationContainer>
    );
  }
}
